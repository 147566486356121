.advanced-booking-form {
  .field {
    margin: 0;
  }

  .layout__last {
    flex: 0 0 20vw !important;
    min-width: 32rem !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__back-link {
    text-align: right;
    width: 100%;
    cursor: pointer;
    position: relative;
    top: 2.5rem;

    &::before {
      color: $green;
      content: '\f053';
      display: inline-block;
      font-family: $iconFontFamily;
      font-weight: 900;
      padding-right: 1rem;
    }

    a {
      color: $black;
      float: right;
      font-size: 1.6rem;
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__select-timeslot-warning {
    color: $white;
    background-color: $red;
    padding: 1rem 2rem;

    > * {
      padding-right: 1rem;
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    margin: 1rem 0;

    > * {
      flex: 1;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__host {
    position: relative;
    z-index: 50;
  }

  &__add-host {
    position: absolute;
    right: 2rem;
  }

  &__booking-description {
    border: 1px solid $midGrey;
    background-color: $lightGrey;
    color: $darkGrey;
    font-size: 1.6rem;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }

  &__slots {
    .booking-slots {
      padding: 0
    }
  }

  &__spaces {
    display: flex;
    flex-flow: row wrap;

    > * {
      $space-card-gap: 1rem;

      flex: 0 0 calc(50% - #{$space-card-gap} / 2);

      &:nth-child(even) {
        margin-left: $space-card-gap;
      }
    }
  }
}
